import {House} from "./type";
import {Table} from "antd";
import React from "react";

export const HouseRoomsTable: React.FC<{house: House}> = ({house}) => {
  return (
    <Table
      rowKey={'name'}
      pagination={false}
      columns={[
        {
          title: '房间',
          dataIndex: 'name',
          key: 'name'
        },
        {
          title: '数量',
          dataIndex: 'count',
          key: 'count'
        },
        {
          title: '面积',
          dataIndex: 'area',
          key: 'area'
        },
        {
          title: '朝向',
          dataIndex: 'direction',
          key: 'direction'
        },
        {
          title: '窗户',
          dataIndex: 'window',
          key: 'window'
        }
      ]}
      dataSource={[
        {
          name: '厅',
          count: house.rooms_living_room_count,
          area: house.area_living_room,
          direction: house.direction_living_room,
          window: house.window_living_room
        },
        {
          name: '卧室',
          count: house.rooms_bedroom_count,
          area: house.area_bedroom,
          direction: house.direction_bedroom,
          window: house.window_bedroom
        },
        {
          name: '厨房',
          count: house.rooms_kitchen_count,
          area: house.area_kitchen,
          direction: house.direction_kitchen,
          window: house.window_kitchen
        },
        {
          name: '卫生间',
          count: house.rooms_restroom_count,
          area: house.area_restroom,
          direction: house.direction_restroom,
          window: house.window_restroom
        },
        {
          name: '阳台',
          count: house.rooms_balcony_count,
          area: house.area_balcony,
          direction: house.direction_balcony,
          window: house.window_balcony
        },
        {
          name: '露台',
          count: house.rooms_platform_count,
          area: house.area_platform,
          direction: '-',
          window: '-'
        },
        {
          name: '储物间',
          count: (house.area_storage ?? 0) > 0 ? 1 : 0,
          area: house.area_storage,
          direction: '-',
          window: '-'
        },
        {
          name: '衣帽间',
          count: (house.area_dressing ?? 0) > 0 ? 1 : 0,
          area: house.area_dressing,
          direction: '-',
          window: '-'
        },
        {
          name: '套内总计',
          count: (house.rooms_platform_count ?? 0) +
            (house.rooms_balcony_count ?? 0) +
            (house.rooms_bedroom_count ?? 0) +
            (house.rooms_kitchen_count ?? 0) +
            (house.rooms_living_room_count ?? 0) +
            (house.rooms_restroom_count ?? 0),
          area: house.area_usable,
          direction: house.area_usable && house.area_total ? '得房率：' + ((house.area_usable / house.area_total) * 100).toFixed(1) + '%' : '-',
        }
      ].filter(({ count }) => count && (typeof count === 'string' || count > 0))}
    />
  )
};