export enum HouseSellStatus {
  DEFAULT = 0,
  DISABLED = 1,
  SOLD = 2,
}

export enum ParkingStatus {
  NONE = 0,
  EASY = 1,
  REGULAR = 2,
}

export type HousePic = {
  name: string;
  url: string;
}

export type ApiRes<T = never> = {
  success: boolean;
  successInfo: T;
}

export type SimpleHouse = Pick<House, 'house_id' | 'yard_name' | 'house_name' | 'house_pic' | 'area_total' | 'area_usable' | 'building_year' | 'building_floor_type' | 'price_total' | 'price_per_area' | 'house_type' | 'house_use' | 'location_city' | 'location_street' | 'location_ring' | 'house_pics' | 'rooms_picture' | 'rooms_living_room_count' | 'rooms_bedroom_count' | 'rooms_balcony_count' | 'building_total_floors' | 'parking_status' | 'house_sell_status' | 'update_time' | 'url' | 'tax_the_only_house' | 'tax_5_years_since_last_transaction' | 'area_dressing' | 'area_platform' | 'has_nice_window' | 'edu_primary_school'>

export type House = {
  house_id: string;
  house_name: string;
  house_pic?: string;

  location_city?: string;
  location_street?: string;
  location_ring?: string;

  yard_name?: string;
  building_year?: number;
  area_total?: number;
  area_usable?: number;
  area_living_room?: number;
  area_bedroom?: number;
  area_kitchen?: number;
  area_restroom?: number;
  area_balcony?: number;
  area_platform?: number;
  price_total?: number;
  price_per_area?: number;
  price_per_usable_area?: number;
  building_total_floors?: number;
  building_floor_type?: string;
  building_type?: string;
  sell_start_time?: number;
  last_transaction_time?: number;
  traffic_nearest_subway_line?: string;
  traffic_nearest_subway_station?: string;
  traffic_nearest_subway_station_distance?: number;
  house_type?: string;
  house_use?: string;
  house_mortgage?: boolean;
  house_mortgage_content?: string;
  house_lift?: boolean;
  house_floor_member_per_lift?: number;
  tax_5_years_since_last_transaction?: boolean;
  tax_the_only_house?: boolean;
  rooms_living_room_count?: number;
  rooms_bedroom_count?: number;
  rooms_kitchen_count?: number;
  rooms_restroom_count?: number;
  rooms_balcony_count?: number;
  rooms_platform_count?: number;
  direction_living_room?: string;
  direction_bedroom?: string;
  direction_kitchen?: string;
  direction_restroom?: string;
  direction_balcony?: string;
  window_living_room?: string;
  window_bedroom?: string;
  window_kitchen?: string;
  window_restroom?: string;
  window_balcony?: string;
  area_storage?: number;
  area_dressing?: number;
  location_latitude?: number;
  location_longitude?: number;
  url?: string;
  rooms_picture?: string;
  update_time?: number;
  description_core_benefit?: string;
  description_layout?: string;
  description_around?: string;
  description_traffic?: string;
  house_pics?: HousePic[];
  house_sell_status?: HouseSellStatus;
  parking_status?: ParkingStatus;
  has_nice_window?: boolean;
  collected: boolean;
  edu_primary_school: string;
};