export const API_HOST = 'https://www.xiongdianpku.com';

export function imageWrapper(src: string) {
  return `${API_HOST}/api/test/pic?src=${encodeURIComponent(src)}`;
}

export function dateFormat(timestamp: number | undefined) {
  if (!timestamp) {
    return '未知';
  }
  const date = new Date(timestamp * 1000);
  return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
}

export function valueFormat(str: any | undefined) {
  return str || '暂无';
}

export function boolFormat(bool: boolean | undefined) {
  if (bool === undefined) {
    return '未知';
  }
  return bool ? '是' : '否';
}