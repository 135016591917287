import React, {useEffect, useState} from 'react';
import {ApiRes, House} from "./type";
import {NavLink, useParams} from "react-router-dom";
import axios from "axios";
import {API_HOST, boolFormat, dateFormat, imageWrapper, valueFormat} from "./utils";
import {Button, Descriptions, Spin} from "antd";
import './style.css';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {HouseInfo} from "./HouseInfo";
import {HouseRoomsTable} from "./HouseRoomsTable";
import {HeartFilled, HeartOutlined, LeftOutlined, LinkOutlined} from "@ant-design/icons";

export const HouseDetail: React.FC = props => {
  const [house, setHouse] = useState<House>();
  const [loading, setLoading] = useState(false);

  const [fullscreen, setFullscreen] = useState(false);
  const { house_id } = useParams();

  useEffect(() => {
    setLoading(true);
    axios.get<ApiRes<House>>(`${API_HOST}/api/house/detail/${house_id}`).then(res => res.data).then(res => {
      setLoading(false);
      setHouse(res.successInfo);
    });
  }, [house_id]);

  if (loading || !house) {
    return <Spin spinning={true}/>
  } else {
    return (
      <div className={'house-detail-container'}>
        <div className={'house-detail'}>
          <NavLink to={'/house'}>
            <Button className={'back-button'} icon={<LeftOutlined />} shape={'circle'} type={'default'}/>
          </NavLink>
          <Carousel
            className={`carousel ${fullscreen ? 'fullscreen' : ''}`}
            swipeable={true}
            infiniteLoop={true}
            onClickItem={() => setFullscreen(!fullscreen)}
          >
            {(house.house_pics ?? []).map((pic, index) => (
              <div className={'carousel-cell'} key={index}>
                <img alt={'房屋图片'} src={imageWrapper(pic.url)}/>
                <p>{pic.name}</p>
              </div>
            ))}
          </Carousel>
          <div className={'content'}>
            <div className={'info'}>
              <HouseInfo house={house}/>
            </div>
            <div className={'buttons'}>
              <Button type={'primary'} icon={<LinkOutlined />} onClick={() => window.open(house.url, '_blank')}>去链家查看</Button>
              <Button type={'default'} icon={house.collected ? <HeartFilled /> : <HeartOutlined />} onClick={() => {
                const oldCollected = house.collected;
                setHouse({
                  ...house,
                  collected: !oldCollected
                });
                axios.post<ApiRes>(`${API_HOST}/api/house/detail/${house.house_id}/collect`, {
                  collected: !oldCollected
                }).then(res => res.data).then(({ success }) => {
                  if (!success) {
                    setHouse({
                      ...house,
                      collected: oldCollected
                    })
                  }
                });
              }}>{house.collected ? '取消收藏' : '收藏'}</Button>
            </div>
            <h3>户型</h3>
            {house.rooms_picture && <img alt={'户型图'} className={'rooms'} src={imageWrapper(house.rooms_picture)}/>}
            <HouseRoomsTable house={house}/>
            <h3>其它信息</h3>
            <Descriptions layout={'horizontal'}>
              <Descriptions.Item label={'挂牌时间'}>{dateFormat(house.sell_start_time)}</Descriptions.Item>
              <Descriptions.Item label={'上次交易'}>{dateFormat(house.last_transaction_time)}</Descriptions.Item>
              <Descriptions.Item label={'抵押'}>{valueFormat(house.house_mortgage_content)}</Descriptions.Item>
              <Descriptions.Item label={'最近地铁线'}>{valueFormat(house.traffic_nearest_subway_line)}</Descriptions.Item>
              <Descriptions.Item label={'地铁站'}>{valueFormat(house.traffic_nearest_subway_station)}</Descriptions.Item>
              <Descriptions.Item label={'电梯'}>{boolFormat(house.house_lift)}</Descriptions.Item>
              <Descriptions.Item label={'梯户比'}>{valueFormat(house.house_floor_member_per_lift)}</Descriptions.Item>
              <Descriptions.Item label={'满五'}>{boolFormat(house.tax_5_years_since_last_transaction)}</Descriptions.Item>
              <Descriptions.Item label={'唯一'}>{boolFormat(house.tax_the_only_house)}</Descriptions.Item>
              <Descriptions.Item span={3} label={'更新于'}>{dateFormat(house.update_time)}</Descriptions.Item>
              <Descriptions.Item span={3} label={'核心卖点'}>{valueFormat(house.description_core_benefit)}</Descriptions.Item>
              <Descriptions.Item span={3} label={'户型'}>{valueFormat(house.description_layout)}</Descriptions.Item>
              <Descriptions.Item span={3} label={'周边'}>{valueFormat(house.description_around)}</Descriptions.Item>
              <Descriptions.Item span={3} label={'交通'}>{valueFormat(house.description_traffic)}</Descriptions.Item>
            </Descriptions>
          </div>
        </div>
      </div>
    );
  }
}