import React, {useState} from 'react';
import {Input, Modal} from "antd";
import axios from "axios";
import {Outlet} from "react-router-dom";

const AUTH_CODE_STORAGE_KEY = 'house_auth_code';

axios.interceptors.request.use(config => {
  const token = localStorage.getItem(AUTH_CODE_STORAGE_KEY);
  if (token) {
    config.headers!['X-Auth-Code'] = token;
  }
  return config;
});

export const AuthCodeWrapper: React.FC = props => {
  // for auth code
  const [authCode, setAuthCode] = useState(localStorage.getItem(AUTH_CODE_STORAGE_KEY));
  const [authCodeInput, setAuthCodeInput] = useState('');

  return !authCode ? (
    <Modal
      title={'欢迎使用'}
      visible={!authCode}
      cancelButtonProps={{
        style: {
          display: 'none'
        }
      }}
      okButtonProps={{
        disabled: !authCodeInput
      }}
      onOk={() => {
        setAuthCode(authCodeInput);
        localStorage.setItem(AUTH_CODE_STORAGE_KEY, authCodeInput);
      }}
    >
      <Input
        value={authCodeInput}
        onChange={e => setAuthCodeInput(e.target.value)}
        placeholder={'首次使用请输入授权码'}
      />
    </Modal>
  ) : <Outlet/>;
}