import React from 'react';
import HeaderImagePoker from './poker.jpg';
import HeaderImageCode from './promotee.png';
import './Introduction.css';

export const Introduction: React.FC = (props => {
  return (
    <div className={'introduction'}>
        <h1>Iodine Poker</h1>
        <img src={HeaderImagePoker} className={'header-image'}/>
        <p>{'play real Texas Hold\'em on Apple Vision Pro'}</p>
        <hr />
        <h1>Iodine Code</h1>
        <img src={HeaderImageCode} className={'header-image'}/>
        <p>{'-> git:(master) Your git client right on your iPhone'}</p>
    </div>
  );
})