import React from 'react';
import {HouseSellStatus, ParkingStatus, SimpleHouse} from "./type";
import {CompassOutlined, InfoCircleOutlined} from "@ant-design/icons";
import './houseInfo.css';
import {NavLink} from "react-router-dom";
import {Tag} from "antd";

enum Tags {
  TAX,
  PARK_GOOD,
  PARK_BEST,
  PLATFORM,
  DRESSING,
  GOOD_WINDOW,
  NEW_HOUSE,
}

export const HouseInfo: React.FC<{house: SimpleHouse}> = ({ house }) => {
  const tags: Tags[] = [];
  if (house.tax_5_years_since_last_transaction && house.tax_the_only_house) {
    tags.push(Tags.TAX);
  }
  if (house.parking_status === ParkingStatus.EASY) {
    tags.push(Tags.PARK_GOOD);
  }
  if (house.parking_status === ParkingStatus.REGULAR) {
    tags.push(Tags.PARK_BEST);
  }
  if (house.area_dressing && house.area_dressing > 0) {
    tags.push(Tags.DRESSING);
  }
  if (house.area_platform && house.area_platform > 0) {
    tags.push(Tags.PLATFORM);
  }
  if (house.has_nice_window) {
    tags.push(Tags.GOOD_WINDOW);
  }
  if (house.building_year && house.building_year >= 2000) {
    tags.push(Tags.NEW_HOUSE);
  }
  return (
    <div className={'house-info'}>
      <div className={'info-container'}>
        <NavLink className={'title'} to={`/house/detail/${house.house_id}`}>
          {house.house_name}
        </NavLink>
        {
          house.house_sell_status === HouseSellStatus.SOLD ? <Tag color={'#777'}>已成交</Tag> :
            house.house_sell_status === HouseSellStatus.DISABLED ? <Tag color={'#777'}>已下架</Tag> : null
        }
        <div className={'tags'}>
          {tags.map(tag => ({
            [Tags.TAX]: <Tag key={Tags.TAX} color={'magenta'}>满五唯一</Tag>,
            [Tags.PARK_GOOD]: <Tag key={Tags.PARK_GOOD} color={'red'}>好停车</Tag>,
            [Tags.PARK_BEST]: <Tag key={Tags.PARK_BEST} color={'gold'}>固定车位</Tag>,
            [Tags.PLATFORM]: <Tag key={Tags.PLATFORM} color={'lime'}>带露台</Tag>,
            [Tags.DRESSING]: <Tag key={Tags.DRESSING} color={'cyan'}>衣帽间</Tag>,
            [Tags.GOOD_WINDOW]: <Tag key={Tags.GOOD_WINDOW} color={'blue'}>落地窗</Tag>,
            [Tags.NEW_HOUSE]: <Tag key={Tags.NEW_HOUSE} color={'green'}>新房</Tag>,
          }[tag]))}
        </div>
        <div>
          <CompassOutlined />
          <span>{(() => {
            const comps = [];
            if (house.yard_name) {
              comps.push(house.yard_name);
            }
            if (house.location_city) {
              comps.push(`${house.location_city}${house.location_street ? ` - ${house.location_street}` : ''}`);
            }
            if (house.location_ring) {
              comps.push(house.location_ring);
            }
            return comps.join(' | ');
          })()}
          </span>
        </div>
        <div>
          <InfoCircleOutlined />
          <span>{(() => {
            const comps = [];
            if ((house.rooms_bedroom_count ?? 0) + (house.rooms_living_room_count ?? 0) > 0) {
              comps.push(`${house.rooms_bedroom_count}室${house.rooms_living_room_count}厅`);
            }
            if (house.area_total) {
              comps.push(`${house.area_total} 平米${house.area_usable ? `（套内 ${house.area_usable} 平米）` : ''}`);
            }
            if (house.building_floor_type) {
              comps.push(`${house.building_floor_type}${house.building_total_floors ? `（共${house.building_total_floors}层）` : ''}`);
            }
            if (house.building_year) {
              comps.push(`${house.building_year}年建`);
            }
            if (house.house_use) {
              comps.push(`${house.house_type} - ${house.house_use}`);
            }
            if (house.edu_primary_school) {
              comps.push(house.edu_primary_school);
            }
            return comps.join(' | ');
          })()}
          </span>
        </div>
      </div>
      <div className={'price-container'}>
        <div className={'box'}>
          <div className={'total_price'}>
            <span className={'number'}>{house.price_total}</span>
            <span className={'unit'}>万</span>
          </div>
          <div className={'single_price'}>
            {house.price_per_area}元/平
          </div>
        </div>
      </div>
    </div>
  );
}