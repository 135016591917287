import {ApiRes, HouseSellStatus, ParkingStatus} from "./type";
import React, {useEffect, useState} from "react";
import {Button, Col, Collapse, Input, InputNumber, Row, Select, Switch} from "antd";
import axios from "axios";
import {API_HOST} from "./utils";
import {Option} from "antd/es/mentions";
import './filterPanel.css';

export type NumberRange = {
  min?: number;
  max?: number;
};

export type RoomParam = {
  count?: NumberRange;
  area?: NumberRange;
  direction?: string[];
  window?: string[];
};

export type SearchParam = {
  price_total?: NumberRange,
  price_single?: NumberRange,
  location?: {
    city?: string[];
    area?: string[];
    ring?: string[];
  },
  year?: NumberRange;
  total_area?: NumberRange;
  usable_area?: NumberRange;
  total_floors?: NumberRange;
  floor_types?: string[];
  subway_line?: string[];
  subway_station?: string[];
  house_type?: string[];
  house_use?: string[];
  location_ew?: Array<'e' | 'w'>;
  location_sn?: Array<'s' | 'n'>;
  keyword?: string;
  lift_num?: NumberRange;
  rooms?: {
    living_room?: RoomParam;
    bedroom?: RoomParam;
    restroom?: RoomParam;
    kitchen?: RoomParam;
    balcony?: RoomParam;
    platform?: RoomParam;
  }
  parking?: ParkingStatus[];
  sell_status?: HouseSellStatus[];
  special?: Array<'tax' | 'house_mortgage' | 'house_lift' | 'storage' | 'dressing'>;
  collected?: true;
};

type FilterOptions = {
  location_city?: string[];
  location_street?: string[];
  location_ring?: string[];
  building_floor_type?: string[];
  building_type?: string[];
  traffic_nearest_subway_line?: string[];
  traffic_nearest_subway_station?: string[];
  house_type?: string[];
  house_use?: string[];
  window?: string[];
  direction?: string[];
};

const SelectInputWithKeys: React.FC<{ options?: Array<{ label: string; value: string; }>; value?: string[]; onChange: (value?: string[]) => void; placeholder?: string }> = ({ placeholder, options, value, onChange }) => {
  return (
    <Select mode={'multiple'} value={value} onChange={v => onChange(v)} style={{ width: 194 }} placeholder={placeholder}>
      {
        (options ?? []).map(({ label, value }) => (
          <Option value={value}>{label}</Option>
        ))
      }
    </Select>
  );
};

const SelectInput: React.FC<{ options?: string[]; value?: string[]; onChange: (value?: string[]) => void; placeholder?: string }> = ({ placeholder, options, value, onChange }) => {
  return (
    <Select mode={'multiple'} value={value} onChange={v => onChange(v)} style={{ width: 194 }} placeholder={placeholder}>
      {
        (options ?? []).map(option => (
          <Option value={option}>{option}</Option>
        ))
      }
    </Select>
  );
};

const RangeInput: React.FC<{ unit?: string; value?: NumberRange; onChange: (range: NumberRange) => void }> = ({unit, value, onChange}) => {
  return (
    <>
      <InputNumber
        placeholder={unit}
        value={value?.min}
        onChange={v => onChange({
          ...value,
          min: v
        })}
      />
      &nbsp;-&nbsp;
      <InputNumber
        placeholder={unit}
        value={value?.max}
        onChange={v => onChange({
          ...value,
          max: v
        })}
      />
    </>
  );
};

const FormItem: React.FC<{label: string; layout: 'third' | 'quarter' | 'full'}> = ({ label, layout, children }) => {
  const content = <><span className={'form-item-label'}>{label}：</span>{children}</>
  if (layout === 'third') {
    return (<Col lg={8} md={12} xs={24}>
      {content}
    </Col>);
  } else if (layout === 'quarter') {
    return (<Col lg={6} md={12} xs={24}>
      {content}
    </Col>);
  } else {
    return (<Col span={24}>
      {content}
    </Col>);
  }
};

export const FilterConditionBuilder: React.FC<{ defaultParams?: SearchParam,  filterOptions: FilterOptions; onChange: (params: SearchParam) => void }> = ({ defaultParams, filterOptions, onChange }) => {
  const [searchParams, setSearchParams] = useState<SearchParam>(defaultParams ?? {});

  const rooms: Array<{
    key: keyof Required<SearchParam>['rooms'],
    name: string
  }> = [
    {
      key: 'living_room',
      name: '厅'
    },
    {
      key: 'bedroom',
      name: '卧室'
    },
    {
      key: 'kitchen',
      name: '厨房'
    },
    {
      key: 'restroom',
      name: '卫生间'
    },
    {
      key: 'balcony',
      name: '阳台'
    },
    {
      key: 'platform',
      name: '露台'
    }
  ];

  return (
    <>
      <Row>
        <FormItem label={'关键词'} layout={'full'}>
          <Input style={{ width: '50%' }} placeholder={'搜索名称、小区、描述等'} value={searchParams.keyword} onChange={e => setSearchParams({
            ...searchParams,
            keyword: e.target.value
          })}/>
        </FormItem>
      </Row>
      <Row>
        <FormItem label={'总价区间'} layout={'third'}>
          <RangeInput
            unit={'万元'}
            value={searchParams.price_total}
            onChange={v => setSearchParams({
              ...searchParams,
              price_total: v
            })}
          />
        </FormItem>
        <FormItem label={'单价'} layout={'third'}>
          <RangeInput
            unit={'元'}
            value={searchParams.price_single}
            onChange={v => setSearchParams({
              ...searchParams,
              price_single: v
            })}
          />
        </FormItem>
        <FormItem label={'建筑年份'} layout={'third'}>
          <RangeInput
            value={searchParams.year}
            onChange={v => setSearchParams({
              ...searchParams,
              year: v
            })}
          />
        </FormItem>
      </Row>
      <Row>
        <FormItem label={'行政区'} layout={'third'}>
          <SelectInput
            placeholder={'请选择行政区'}
            options={filterOptions.location_city}
            value={searchParams.location?.city}
            onChange={value => setSearchParams({
              ...searchParams,
              location: {
                ...searchParams.location,
                city: value
              }
            })}
          />
        </FormItem>
        <FormItem label={'片区'} layout={'third'}>
          <SelectInput
            placeholder={'请选择片区'}
            options={filterOptions.location_street}
            value={searchParams.location?.area}
            onChange={value => setSearchParams({
              ...searchParams,
              location: {
                ...searchParams.location,
                area: value
              }
            })}
          />
        </FormItem>
        <FormItem label={'环线'} layout={'third'}>
          <SelectInput
            placeholder={'请选择环线'}
            options={filterOptions.location_ring}
            value={searchParams.location?.ring}
            onChange={value => setSearchParams({
              ...searchParams,
              location: {
                ...searchParams.location,
                ring: value
              }
            })}
          />
        </FormItem>
      </Row>
      <Row>
        <FormItem label={'总面积'} layout={'third'}>
          <RangeInput
            unit={'平米'}
            value={searchParams.total_area}
            onChange={v => setSearchParams({
              ...searchParams,
              total_area: v
            })}
          />
        </FormItem>
        <FormItem label={'套内面积'} layout={'third'}>
          <RangeInput
            unit={'平米'}
            value={searchParams.usable_area}
            onChange={v => setSearchParams({
              ...searchParams,
              usable_area: v
            })}
          />
        </FormItem>
        <FormItem label={'房屋用途'} layout={'third'}>
          <SelectInput
            placeholder={'请选择房屋用途'}
            options={filterOptions.house_use}
            value={searchParams.house_use}
            onChange={value => setSearchParams({
              ...searchParams,
              house_use: value
            })}
          />
        </FormItem>
      </Row>
      <Row>
        <FormItem label={'楼层描述'} layout={'third'}>
          <SelectInput
            placeholder={'请选择楼层描述'}
            options={filterOptions.building_floor_type}
            value={searchParams.floor_types}
            onChange={value => setSearchParams({
              ...searchParams,
              floor_types: value
            })}
          />
        </FormItem>
        <FormItem label={'总楼高'} layout={'third'}>
          <RangeInput
            unit={'层'}
            value={searchParams.total_floors}
            onChange={v => setSearchParams({
              ...searchParams,
              total_floors: v
            })}
          />
        </FormItem>
        <FormItem label={'产权类型'} layout={'third'}>
          <SelectInput
            placeholder={'请选择产权类型'}
            options={filterOptions.house_type}
            value={searchParams.house_type}
            onChange={value => setSearchParams({
              ...searchParams,
              house_type: value
            })}
          />
        </FormItem>
      </Row>
      <Row>
        <FormItem label={'临近地铁线'} layout={'third'}>
          <SelectInput
            placeholder={'请选择临近地铁线'}
            options={filterOptions.traffic_nearest_subway_line}
            value={searchParams.subway_line}
            onChange={value => setSearchParams({
              ...searchParams,
              subway_line: value
            })}
          />
        </FormItem>
        <FormItem label={'临近地铁站'} layout={'third'}>
          <SelectInput
            placeholder={'请选择临近地铁站'}
            options={filterOptions.traffic_nearest_subway_station}
            value={searchParams.subway_station}
            onChange={value => setSearchParams({
              ...searchParams,
              subway_station: value
            })}
          />
        </FormItem>
        <FormItem label={'每梯户数'} layout={'third'}>
          <RangeInput
            value={searchParams.lift_num}
            onChange={v => setSearchParams({
              ...searchParams,
              lift_num: v
            })}
          />
        </FormItem>
      </Row>
      <Row>
        <FormItem label={'位置（东-西）'} layout={'third'}>
          <SelectInputWithKeys
            placeholder={'位于天安门东/西'}
            options={[{label: '东', value: 'e'}, {label: '西', value: 'w'}]}
            value={searchParams.location_ew}
            onChange={value => setSearchParams({
              ...searchParams,
              location_ew: value as Array<'e' | 'w'>
            })}
          />
        </FormItem>
        <FormItem label={'位置（南-北）'} layout={'third'}>
          <SelectInputWithKeys
            placeholder={'位于天安门南/北'}
            options={[{label: '南', value: 's'}, {label: '北', value: 'n'}]}
            value={searchParams.location_sn}
            onChange={value => setSearchParams({
              ...searchParams,
              location_sn: value as Array<'s' | 'n'>
            })}
          />
        </FormItem>
        {/*'tax' | 'house_mortgage' | 'house_lift' | 'storage' | 'dressing'*/}
        <FormItem label={'特殊要求'} layout={'third'}>
          <SelectInputWithKeys
            placeholder={'请选择特殊要求'}
            options={[
              {label: '满五唯一', value: 'tax'},
              {label: '无抵押', value: 'house_mortgage'},
              {label: '有电梯', value: 'house_lift'},
              {label: '带储物间', value: 'storage'},
              {label: '带衣帽间', value: 'dressing'},
            ]}
            value={searchParams.special}
            onChange={value => setSearchParams({
              ...searchParams,
              special: value as SearchParam['special']
            })}
          />
        </FormItem>
      </Row>
      <Row>
        <FormItem label={'售卖状态'} layout={'third'}>
          <SelectInputWithKeys
            placeholder={'请选择售卖状态'}
            options={[
              {label: '挂牌中', value: `${HouseSellStatus.DEFAULT}`},
              {label: '已成交', value: `${HouseSellStatus.SOLD}`},
              {label: '已下架', value: `${HouseSellStatus.DISABLED}`},
            ]}
            value={searchParams.sell_status?.map(one => `${one}`)}
            onChange={value => setSearchParams({
              ...searchParams,
              sell_status: (value ?? []).map(one => parseInt(one))
            })}
          />
        </FormItem>
        <FormItem label={'停车位'} layout={'third'}>
          <SelectInputWithKeys
            placeholder={'请选择停车位'}
            options={[
              {label: '普通', value: `${ParkingStatus.NONE}`},
              {label: '容易停车', value: `${ParkingStatus.EASY}`},
              {label: '有固定车位', value: `${ParkingStatus.REGULAR}`},
            ]}
            value={searchParams.parking?.map(one => `${one}`)}
            onChange={value => setSearchParams({
              ...searchParams,
              parking: (value ?? []).map(one => parseInt(one))
            })}
          />
        </FormItem>
        <FormItem label={'只看已收藏'} layout={'third'}>
          <Switch checked={searchParams.collected} onChange={checked => {
            const { collected, ...rest } = searchParams;
            setSearchParams({
              ...rest,
              ...(checked ? { collected: true } : {})
            });
          }}/>
        </FormItem>
      </Row>
      {
        rooms.map(({ key, name }) => (
          <Row>
            <FormItem label={`${name}数量`} layout={'quarter'}>
              <RangeInput
                unit={'间'}
                value={searchParams.rooms?.[key]?.count}
                onChange={v => setSearchParams({
                  ...searchParams,
                  rooms: {
                    ...searchParams.rooms,
                    [key]: {
                      ...searchParams.rooms?.[key],
                      count: v
                    }
                  }
                })}
              />
            </FormItem>
            <FormItem label={`${name}面积`} layout={'quarter'}>
              <RangeInput
                unit={'平米'}
                value={searchParams.rooms?.[key]?.area}
                onChange={v => setSearchParams({
                  ...searchParams,
                  rooms: {
                    ...searchParams.rooms,
                    [key]: {
                      ...searchParams.rooms?.[key],
                      area: v
                    }
                  }
                })}
              />
            </FormItem>
            <FormItem label={`${name}朝向`} layout={'quarter'}>
              <SelectInput
                placeholder={`请选择${name}朝向`}
                options={filterOptions.direction}
                value={searchParams.rooms?.[key]?.direction}
                onChange={v => setSearchParams({
                  ...searchParams,
                  rooms: {
                    ...searchParams.rooms,
                    [key]: {
                      ...searchParams.rooms?.[key],
                      direction: v
                    }
                  }
                })}
              />
            </FormItem>
            <FormItem label={`${name}窗户`} layout={'quarter'}>
              <SelectInput
                placeholder={`请选择${name}窗户类型`}
                options={filterOptions.window}
                value={searchParams.rooms?.[key]?.window}
                onChange={v => setSearchParams({
                  ...searchParams,
                  rooms: {
                    ...searchParams.rooms,
                    [key]: {
                      ...searchParams.rooms?.[key],
                      window: v
                    }
                  }
                })}
              />
            </FormItem>
          </Row>
        ))
      }
      <Button type={'primary'} onClick={() => onChange(searchParams)}>搜索</Button> &nbsp;&nbsp;
      <Button type={'default'} onClick={() => setSearchParams({})}>清除所有条件</Button>
    </>
  );
};

export type AllSearchParam = {
  normal?: SearchParam;
  reverse?: SearchParam;
}

export const FilterPanel: React.FC<{defaultParams?: AllSearchParam, onChange: (params: AllSearchParam) => void}> = ({ defaultParams, onChange }) => {
  const [filterOptions, setFilterOptions] = useState<FilterOptions>({});
  const [searchParams, setSearchParams] = useState<AllSearchParam>(defaultParams ?? {});
  const [updateIndex, setUpdateIndex] = useState(0);

  useEffect(() => {
    axios.get<ApiRes<FilterOptions>>(`${API_HOST}/api/house/filter/options`).then(res => res.data)
      .then(res => setFilterOptions(res.successInfo));
  }, []);

  useEffect(() => {
    if (updateIndex > 0) {
      onChange(searchParams);
    }
  }, [searchParams]);

  useEffect(() => {
    setUpdateIndex(updateIndex + 1);
  }, [searchParams])

  return (
    <div className={'filter-panel'}>
      <Collapse className={'filter'}>
        <Collapse.Panel key={'default-filter'} header={'正向筛选条件'}>
          <FilterConditionBuilder
            defaultParams={searchParams.normal}
            onChange={value => setSearchParams({
              ...searchParams,
              normal: value
            })}
            filterOptions={filterOptions}
          />
        </Collapse.Panel>
        <Collapse.Panel key={'reversed-filter'} header={'反向筛选条件'}>
          <FilterConditionBuilder
            defaultParams={searchParams.reverse}
            onChange={value => setSearchParams({
              ...searchParams,
              reverse: value
            })}
            filterOptions={filterOptions}
          />
        </Collapse.Panel>
      </Collapse>
    </div>
  );
}