import React from 'react';
import { Routes, Route, BrowserRouter } from 'react-router-dom';
import {Introduction} from "./Introduction";
import {Privacy} from "./Privacy";
import {AppLayout} from "./Layout";
import {House} from "./house/House";
import {HouseDetail} from "./house/HouseDetail";
import {AuthCodeWrapper} from "./house/AuthCodeWrapper";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={'/'} element={<AppLayout/>}>
          <Route index element={<Introduction/>}/>
          <Route path={'/privacy'} element={<Privacy/>}/>
        </Route>
        <Route path={'/house'} element={<AuthCodeWrapper/>}>
          <Route index element={<>
            <div>
              <House/>
            </div>
          </>}/>
          <Route path={'detail/:house_id'} element={<>
            <div style={{ display: 'none' }}>
              <House/>
            </div>
            <HouseDetail/>
          </>}/>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
