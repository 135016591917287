import React, {useCallback, useEffect, useRef} from 'react';
import {useState} from "react";
import {Spin} from 'antd';
import axios from 'axios';
import {ApiRes, SimpleHouse} from "./type";

import './style.css';
import {API_HOST, imageWrapper} from "./utils";
import {HouseInfo} from "./HouseInfo";
import {AllSearchParam, FilterPanel} from "./FilterPanel";

const SEARCH_PARAM_STORAGE_KEY = 'search_params';

export const House: React.FC = props => {
  // for house loading
  const [houses, setHouses] = useState<SimpleHouse[]>([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  let defaultParams = {};
  try {
    defaultParams = JSON.parse(localStorage.getItem(SEARCH_PARAM_STORAGE_KEY) ?? '{}');
  } catch (e) {

  }
  const [searchParams, setSearchParams] = useState<AllSearchParam>(defaultParams);
  const [loadingIndex, setLoadingIndex] = useState(0);
  const container = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setLoading(true);
    axios.post<ApiRes<{
      houses: SimpleHouse[],
      count: number,
      total_page: number,
    }>>(`${API_HOST}/api/house/search?page=${page}`, searchParams).then(res => res.data).then(res => {
      const { houses: newHouses, count, total_page } = res.successInfo;
      setLoading(false);
      setCount(count);
      setTotalPages(total_page);
      setHouses([...houses, ...newHouses]);
    });
  }, [loadingIndex]);

  const onScroll = useCallback((e: React.UIEvent<HTMLDivElement, UIEvent>) => {
    console.log(container.current!.scrollTop + container.current!.clientHeight, container.current!.scrollHeight, !loading, page, totalPages);
    if (container.current!.scrollTop + container.current!.clientHeight > container.current!.scrollHeight - 100 && !loading && page < totalPages - 1) {
      setPage(page + 1);
      setLoadingIndex(loadingIndex + 1);
    }
  }, [loading, page, setPage, totalPages]);

  return (
    <div className={'house'} ref={container} onWheel={onScroll} onScroll={onScroll}>
      <div>
        <FilterPanel
          defaultParams={searchParams}
          onChange={value => {
            setSearchParams(value);
            localStorage.setItem(SEARCH_PARAM_STORAGE_KEY, JSON.stringify(value));
            setPage(0);
            setHouses([]);
            setLoadingIndex(loadingIndex + 1);
          }}
        />
        {
          loading && !houses.length ? <Spin spinning={true}/> : (<>
            <p className={'statistics'}>共有{count}个符合要求的房子🏠</p>
            <div className={'house-list'}>
              {
                houses.map((house, index) => {
                  return (
                    <div key={house.house_id} className={`house-card ${index === houses.length - 1 ? 'last' : ''}`}>
                      <div className={'left'}>
                        {
                          house.house_pic ? <img alt={'房屋图片'} src={imageWrapper(house.house_pic)}/> : <div className={'empty'}/>
                        }
                      </div>
                      <div className={'middle'}>
                        <HouseInfo house={house}/>
                      </div>
                    </div>
                  )
                })
              }
            </div>
            {loading ? <Spin /> : null}
          </>)
        }
      </div>
    </div>
  )
};