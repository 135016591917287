import React from 'react';
import {Outlet, useMatch, useResolvedPath} from "react-router";
import { Layout, Menu } from "antd";
import 'antd/dist/antd.css';
import './Layout.css';
import {Link} from "react-router-dom";

const useMatchedKey = (key: string) => {
  let resolved = useResolvedPath(key);
  let match = useMatch({ path: resolved.pathname, end: true });
  return !!match;
};

export const AppLayout: React.FC = (props => {
  const { Header, Content, Footer } = Layout;
  const activeKeys: string[] = [];
  if(useMatchedKey('/privacy')) {
    activeKeys.push('privacy');
  }
  if(useMatchedKey('/')) {
    activeKeys.push('/');
  }
  return (
    <Layout className="layout">
      <Header>
        <div className="logo" />
        <Menu theme="dark" mode="horizontal" selectedKeys={activeKeys}>
          <Menu.Item key={'/'}><Link to={'/'}>Introduction</Link></Menu.Item>
          <Menu.Item key={'privacy'}><Link to={'/privacy'}>Privacy</Link></Menu.Item>
        </Menu>
      </Header>
      <Content style={{ padding: '0 50px' }}>
        <div className="site-layout-content">
          <Outlet/>
        </div>
      </Content>
      <Footer style={{ textAlign: 'center' }}>
        <p>Iodine Softwares ©2017-{new Date().getFullYear()} Created by Dian Xiong</p>
        <p>Contact: <a href={'mailto:x@xiongdianpku.com'}>x@xiongdianpku.com</a></p>
      </Footer>
    </Layout>
  )
})